import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import { InfinitySpin } from "react-loader-spinner";

export default function Home() {
    const [notes, setNotes] = useState([]);
    const [isLoading, setisLoading] = useState([]);
    useEffect(() => {
    setisLoading(true)
    var msg1 = `내 성적은 ${localStorage.grade}. 나는 ${localStorage.InnerO}. 나는 [${localStorage.way}]방식으로 공부해. 이런 나에게 자기주도학습 요인 별 효과적인 학습 전략을 추천해줘`
    console.log(msg1)
    fetch('http://127.0.0.1:777/?message='+msg1+'&tk='+'asdf')
      .then(res => res.json())
      .then(data => {
        console.log(data.msg)
        setNotes(data.msg)
        setisLoading(false)
      })
  }, [])
    return (
      <>
        <header className="App-home">
            <div class="card">
                <div class="container">
                    <h2><b>본인에게 효과적인 학습 방법을 AI가 알려드릴게요</b></h2>
            {isLoading ? (
                <div style={{marginBottom: '10px'}}><InfinitySpin
                visible={true}
                width="500"
                color="#4fa94d"
                ariaLabel="infinity-spin-loading"
                /><br />잠시만기다려주세요</div>
            ) : (<div><p>{notes}</p></div>)}
                </div>
            </div>
        </header>
      </>
      )
    }
    

