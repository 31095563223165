import React, { useEffect, useState } from 'react'

export default function FinalTest() {
  const [questionNumber, setQNum] = useState([])
  const [messagePrompted, setMessage] = useState([])
  const [bmessagePrompted, setbMessage] = useState([])
  useEffect(() => {
    setMessage('본인의 평소 학습 방법을 서술해주세요')
    setQNum('Q6')
    setbMessage('위의 빈칸에 입력을 시작하세요')
  }, [])
  const [text, setText] = useState('')

  const onChange = (e) => {
    setText(e.target.value)
    setbMessage('다음으로 넘어가기')
  }
return (
  <>
  
    <header className="App-home">
      <h1>
      {questionNumber}.<br />
      {messagePrompted}
      </h1>
      <input placeholder='여기에 본인의 평소 공부 방법을 서술해주세요(최대 100자)' onChange={onChange} value={text}  />
      <button
        className="App-link"
        onClick={() => {
          if(text.length == 0 || text.length > 100){
            setbMessage('100자 이내로 입력해주세요.(현재'+text.length+'자)')
          } else {
            localStorage.setItem("way", text)
            (window.location.href="/result")
          }
        }}
        target="_blank"
        style={{marginTop: 10}}
        type="button" 
        rel="noopener noreferrer"
      >{bmessagePrompted}</button>
    </header>
  </>
  )
}
