import React, { useEffect, useState } from 'react'

export default function PreTest() {
  const [questionNumber, setQNum] = useState([])
  const [messagePrompted, setMessage] = useState([])
  useEffect(() => {
    setMessage('본인의 학교생활이 만족스럽나요 ?')
    setQNum('Q1')
  }, [])
return (
  <>
    <header className="App-home">
      <h1>
      {questionNumber}.<br />
      {messagePrompted}
      </h1>
      <button
        className="App-link"
        onClick={() => {
          if(questionNumber == 'Q1'){
            setMessage('어려운 과제를 포기하지 않나요 ?')
            setQNum('Q2')
          } else if(questionNumber == 'Q2') {
            setQNum('더이상 검사할 필요가 없어요')
            setMessage('이미 잘하고 있어요 :)')
          } else {
            (window.location.href="/")
          }
        }}
        target="_blank"
        type="button" 
        rel="noopener noreferrer"
      >
        응
      </button>
      {questionNumber != '더이상 검사할 필요가 없어요' ? (

          <button
        className="App-link"
        onClick={() => (window.location.href="/ftest")}
        target="_blank"
        type="button"
        style={{marginTop: 10}}
        rel="noopener noreferrer"
      >
        아니
      </button>
      ) : (<div></div>)}
      
    </header>
  </>
  )
}
