import Home from "./Pages/Home"
import PreTest from "./Pages/Tests/PreTest"
import FTest from "./Pages/Tests/FTest"
import FinalTest from "./Pages/Tests/FinalTest"
import STest from "./Pages/Tests/STest"
import Result from "./Pages/Result"

import Math from "./Pages/etc/Math"
import Code from "./Pages/etc/Code"

import { Routes, Route, Link } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/pretest" element={<PreTest />} />
        <Route exact path="/ftest" element={<FTest />} />
        <Route exact path="/stest" element={<STest />} />
        <Route exact path="/finaltest" element={<FinalTest />} />
        <Route exact path="/result" element={<Result />} />

        <Route exact path="/math" element={<Math />} />
        <Route exact path="/code" element={<Code />} />
      </Routes>
    </div>
  );
}

export default App;
