import React, { useEffect, useState } from 'react'

export default function STest() {
  const [questionNumber, setQNum] = useState([])
  const [messagePrompted, setMessage] = useState([])
  const [bmessagePrompted, setbMessage] = useState([])
  useEffect(() => {
    setMessage('본인에게 해당하는 항목을 선택해주세요')
    setQNum('Q4')
    setbMessage('본인의 성적을 입력해주세요')
  }, [])
  const [text, setText] = useState('')

  const onChange = (e) => {
    setText(e.target.value)
    setbMessage('다음으로 넘어가기')
  }
return (
  <>
  {questionNumber == 'Q4' ? (
    <header className="App-home">
      <h1>
      {questionNumber}.<br />
      {messagePrompted}
      </h1>
      <button
        className="App-link"
        onClick={() => {
            localStorage.setItem("sdsh", '숙달목표')
            setQNum('Q5')
            setMessage('본인이 생각하는 본인의 성적을 설명해주세요.')
        }}
        target="_blank"
        type="button" 
        rel="noopener noreferrer"
      >
        나는 나의 향상과 이해증진에 목표를 두고 공부해
      </button>
      <button
        className="App-link"
        onClick={() => {
          localStorage.setItem("sdsh", '수행목표')
          setQNum('Q5')
          setMessage('본인이 생각하는 본인의 성적을 설명해주세요.')
        }}
        target="_blank"
        style={{marginTop: 10}}
        type="button" 
        rel="noopener noreferrer"
      >나는 등급을 받기 위해 공부해</button>
      
    </header>
  ) : (
    <header className="App-home">
      <h1>
      {questionNumber}.<br />
      {messagePrompted}
      </h1>
      <input placeholder='여기에 본인의 성적을 서술해주세요(최대 50자) /  ex) 높지만 엄청 높진 않은 편' onChange={onChange} value={text}  />
      <button
        className="App-link"
        onClick={() => {
          if(text.length == 0 || text.length > 50){
            setbMessage('50자 이내로 입력해주세요. ')
          } else {
            localStorage.setItem("grade", text)
            (window.location.href="/finaltest")
          }
          
          
        }}
        target="_blank"
        style={{marginTop: 10}}
        type="button" 
        rel="noopener noreferrer"
      >{bmessagePrompted}</button>
      
    </header>
  )}
    
  </>
  )
}
