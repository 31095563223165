// 내재 vs 외재
import React, { useEffect, useState } from 'react'

export default function Test() {
  const [checkedList, setCheckedList] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const checkBoxListInner = ['해야만 하는 일이니까', '새로운 문제에 도전하고싶어서', '새로운 것을 배우는 것 자체가 즐거우니까', '나는 공부가 즐거워서', '공부를 하다가 호기심이 생기면 그 문제를 해결하고 넘어가야해서', '공부를 하는 동안 집중이 잘되어서', '실수하는 것이 두렵지 않아서', '흥미를 느끼고 있어서']
  const checkBoxListO = ['공부하는 것을 부모님이 원하시니까', '경제적으로 여유있게 살기 위해', '좋은 대학에 다니고 싶어서', '다른 사람과의 경쟁에서 이기기 위해', '돈을 많이 벌기 위해', '훌륭한 사람이 되기 위해', '내 꿈을 이루는 데 필요하니까', '선생님한테 칭찬받기 위해']
  console.log(checkBoxListInner.length, checkBoxListO.length)
  const [questionNumber, setQNum] = useState([])
  const [messagePrompted, setMessage] = useState([])

  const checkedItemHandler = (value, isChecked) => {
    console.log('checkedList:', checkedList)
    if (isChecked) {
      setCheckedList((prev) => [...prev, value])

      return
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value))

      return
    }

    return
  }

  const checkHandler = (e, value) => {
    setIsChecked(!isChecked)
    checkedItemHandler(value, e.target.checked)
  }

  useEffect(() => {
    setMessage('다음중 공부를 하는 이유를 모두 고르세요')
    setQNum('Q3')
  }, [])
return (
  <>
    <header className="App-home">
      <h1>
      {questionNumber}.<br />
      {messagePrompted}
      </h1>
      <div className='checkbox-group'>
  {checkBoxListInner.map((item, idx) => (
    <div className='checkbox' key={idx}>
      <input
        type='checkbox'
        id={item}
        checked={checkedList.includes(item)}
        onChange={(e) => checkHandler(e, item)}
      />
      <label htmlFor={item}>{item}</label>
    </div>
  ))}
  {checkBoxListO.map((item, idx) => (
    <div className='checkbox' key={idx}>
      <input
        type='checkbox'
        id={item}
        checked={checkedList.includes(item)}
        onChange={(e) => checkHandler(e, item)}
      />
      <label htmlFor={item}>{item}</label>
    </div>
  ))}
</div>
  <button
        className="App-link"
        onClick={() => {
          var OCount = 0
          var ICount = 0
          checkedList.map(a => {
            if(checkBoxListInner.includes(a)) {
              ICount = ICount+1
            } else {
              OCount = OCount+1
            }
            console.log(OCount, ICount, a)
          })
          if(ICount == OCount){
            localStorage.setItem("InnerO", '외재적 이유로 공부하는지 내재적 이유로 공부하는지 잘 모르겠어.')
          }
          if(ICount > OCount) {
            localStorage.setItem("InnerO", '내재적 이유로 공부해.')
          }
          if(ICount < OCount) {
            localStorage.setItem("InnerO", '외재적 이유로 공부해.')
          }
            
            (window.location.href="/stest")
        }}
        target="_blank"
        type="button" 
        rel="noopener noreferrer"
      >다음으로 넘어가기</button>
      
    </header>
  </>
  )
}
