
export default function Home() {
return (
  <>
    <header className="App-home">
      <h1>
      반가워요👋<br />
      본인의 자기주도학습능력을 올려봐요
      </h1>
      <button
        className="App-link"
        onClick={() => {
            (localStorage.clear())
            (window.location.href="/pretest")}}
        target="_blank"
        type="button"
        rel="noopener noreferrer"
      >
        자기주도학습에서 도움받기
      </button>
      
    </header>
  </>
  )
}
